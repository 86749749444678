/**
 * If you need to change your route in javascript, e.g. after a click, useRouter
 * is the way to go. Here's some basic usage
 *
 * ```ts
 * import useRouter from "core/hooks/use-router"
 *
 * export const MyComponent = () => {
 *  const router = useRouter()
 *
 *  const { workspace_id } = router.query
 *
 *  return (
 *    <button onClick={() => router.push("/new-route")}>
 *      Go to new route
 *    </button>
 *  )
 * ```
 * }
 */
import { useRouter as useNextRouter } from "next/router"
import { useMemo } from "react"
interface RouterType {
  push: (path: string) => void
  replace: (path: string) => void
  goBack: () => void
  asPath: string
  query: {
    workspace_id?: string
    device_id?: string
    connected_account_id?: string
    connect_webview_id?: string
    acs_system_id?: string
    acs_access_group_id?: string
    acs_user_id?: string
    user_identity_id?: string
  }
}

export const useRouter = (): RouterType => {
  const nextRouter = useNextRouter()

  const router = useMemo(() => {
    return {
      ...nextRouter,
      push: (...args: Parameters<(typeof nextRouter)["push"]>) => {
        if (window.location.pathname === args[0]) return
        if (typeof args[0] === "string" && args[0].includes("/undefined")) {
          console.error("ERROR: push to route with undefined not allowed")
          return
        }
        console.debug(
          `Redirecting from "${window.location.pathname}" to "${args[0]}"`
        )
        // useful for debugging where redirect loops occur
        nextRouter.push.apply(null, args as any) // eslint-disable-line
      },
    }
  }, [nextRouter])

  return router as unknown as RouterType
}
